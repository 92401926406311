import React, { FC, useState } from 'react';

import { XIcon } from './icons';

import { Overlay } from './Overlay';

import TermsOfServiceKr0 from '../assets/img/terms-of-service-consent-kr-0.jpg';
import TermsOfServiceKr1 from '../assets/img/terms-of-service-consent-kr-1.jpg';
import TermsOfServiceKr2 from '../assets/img/terms-of-service-consent-kr-2.jpg';
import TermsOfServiceKr3 from '../assets/img/terms-of-service-consent-kr-3.jpg';
import TermsOfServiceKr4 from '../assets/img/terms-of-service-consent-kr-4.jpg';
import TermsOfServiceKr5 from '../assets/img/terms-of-service-consent-kr-5.jpg';
import TermsOfServiceEn0 from '../assets/img/terms-of-service-consent-en-0.jpg';
import TermsOfServiceEn1 from '../assets/img/terms-of-service-consent-en-1.jpg';
import TermsOfServiceEn2 from '../assets/img/terms-of-service-consent-en-2.jpg';
import TermsOfServiceEn3 from '../assets/img/terms-of-service-consent-en-3.jpg';
import TermsOfServiceEn4 from '../assets/img/terms-of-service-consent-en-4.jpg';

interface PracticeAreaPopupProps {
  open: boolean;
  onClose: () => void;
}

export const TermsOfServiceConsentPopup: FC<PracticeAreaPopupProps> = ({
  open,
  onClose,
}) => {
  const [isKorean, setIsKorean] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const termsOfServiceConsentKr = [
    {
      id: 1,
      filename: TermsOfServiceKr0,
    },

    {
      id: 2,
      filename: TermsOfServiceKr1,
    },

    {
      id: 3,
      filename: TermsOfServiceKr2,
    },

    {
      id: 4,
      filename: TermsOfServiceKr3,
    },
    {
      id: 5,
      filename: TermsOfServiceKr4,
    },
    {
      id: 6,
      filename: TermsOfServiceKr5,
    },
  ];

  const termsOfServiceConsentEn = [
    {
      id: 1,
      filename: TermsOfServiceEn0,
    },

    {
      id: 2,
      filename: TermsOfServiceEn1,
    },

    {
      id: 3,
      filename: TermsOfServiceEn2,
    },

    {
      id: 4,
      filename: TermsOfServiceEn3,
    },
    {
      id: 5,
      filename: TermsOfServiceEn4,
    },
  ];

  // 언어에 따라 terms 데이터를 전환
  const termsOfServiceConsent = isKorean
    ? termsOfServiceConsentKr
    : termsOfServiceConsentEn;

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === termsOfServiceConsent.length - 1 ? 0 : prevIndex + 1,
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? termsOfServiceConsent.length - 1 : prevIndex - 1,
    );
  };

  const currentPopup = termsOfServiceConsent[currentIndex];

  if (!open) return <></>;
  return (
    <div className="fixed z-10 inset-0 grid place-items-center overflow-hidden">
      <Overlay open={open} onClose={onClose} />

      <div
        style={{
          maxHeight: '90vh',
          maxWidth: '40vw',
        }}
        className="relative flex flex-col space-y-2    shadow-lg rounded-lg bg-white overflow-hidden"
      >
        <div className="flex justify-between p-3">
          <h1 className="text-xs sm:text-lg font-bold text-left sm:text-center">
            {isKorean ? '홈페이지 이용약관' : 'Terms of Service'}
          </h1>

          <div className="flex-1 flex justify-end space-x-4">
            <button
              type="button"
              className="text-xs sm:text-sm sm:px-3 sm:py-2 sm:text-base px-4 py-2 border-2 border-black bg-transparent text-black rounded-md hover:bg-gray-100 focus:outline-none"
              onClick={() => {
                setIsKorean(!isKorean);
                setCurrentIndex(0);
              }}
            >
              {'KR / EN'}
            </button>
            <button onClick={onClose}>
              <XIcon />
            </button>
          </div>
        </div>

        <div className="relative  w-full overflow-y-auto rounded-lg">
          {/* 흰색 배경과 공백을 위한 div */}
          <img
            src={currentPopup.filename}
            className="w-full h-full object-contain cursor-pointer rounded-lg"
          />
        </div>
        <div className="absolute bottom-4 right-4 flex space-x-2">
          {termsOfServiceConsentKr.length !== 1 && (
            <>
              <button
                className="p-2 w-10 h-10 bg-white bg-opacity-50 hover:bg-opacity-75 rounded-full shadow-lg"
                onClick={(e) => {
                  e.stopPropagation();
                  handlePrev();
                }}
              >
                <span>&#9664;</span>
              </button>
              <button
                className="p-2 w-10 h-10 bg-white bg-opacity-50 hover:bg-opacity-75 rounded-full shadow-lg"
                onClick={(e) => {
                  e.stopPropagation();
                  handleNext();
                }}
              >
                <span>&#9654;</span>
              </button>
            </>
          )}
        </div>

        <div className="flex justify-center mt-2 mb-2 space-x-2">
          {termsOfServiceConsent.length !== 1 &&
            termsOfServiceConsent.map((_, index) => (
              <span
                key={index}
                className={`block ${
                  currentIndex === index
                    ? 'w-6 h-2 bg-gray-100'
                    : 'w-2 h-2 bg-gray-400'
                } rounded-full`}
              ></span>
            ))}
        </div>
      </div>
    </div>
  );
};
