import React from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { Labeled } from '../../components/Labeled';
import { TextField } from '../../components/TextField';
import { Toggle } from '../../components/Toggle';
import { api } from '../../plugins/axios';
import { EditorView } from '../../plugins/froala';
import { fetcher } from '../../plugins/react-query';
import { Seminar } from '../../types';
import { AdminH1 } from '../components/AdminH1';

export const SeminarsShow = () => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();

  const { data: seminar } = useQuery<Seminar>(
    `/admin/seminars/${id}`,
    fetcher,
    {
      enabled: !!id,
    },
  );

  const remove = async () => {
    await api.delete(`/admin/seminars/${id}`);
    push(`/admin/seminars`);
  };

  if (!seminar) return <></>;
  return (
    <>
      <AdminH1>Seminar Details</AdminH1>

      <Card>
        <div className="space-y-6 px-4 py-6 sm:px-6 md:px-8">
          <TextField
            label="Title"
            type="email"
            value={seminar.title}
            disabled
          />

          <Labeled label="Thumbnail">
            <img src={seminar.thumbnail} alt="thumbnail" className="max-w-xs" />
          </Labeled>

          <Labeled label="Content">
            <div
              style={{ width: '753px' }}
              className="p-4 rounded-xl border border-gray-200"
            >
              <EditorView model={seminar.context} />
            </div>
          </Labeled>
        </div>
        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          <Button
            text="Delete"
            className="h-10 text-sm filled-red-500 hover:bg-red-600"
            onClick={() => window.confirm('Delete this item?') && remove()}
          />
          <Button
            text="Edit"
            to={`/admin/seminars/${id}/edit`}
            className="h-10 text-sm filled-indigo-600 hover:bg-indigo-700"
          />
        </div>
        <div className="px-4 py-6 sm:px-6 md:px-8">
          <div className="flex space-x-2">
            <span>IsHide</span>
            <Toggle checked={seminar.isHide} disabled />
            <p className="text-gray-400">숨김 활성화 여부</p>
          </div>
        </div>
      </Card>
    </>
  );
};
