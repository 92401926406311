import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { Labeled } from '../../components/Labeled';
import { TextField } from '../../components/TextField';
import { Toggle } from '../../components/Toggle';
import { api } from '../../plugins/axios';
import { Editor } from '../../plugins/froala';
import { Notice } from '../../types';
import { AdminH1 } from '../components/AdminH1';

interface FormValues {
  title: string;
  context: string;
  isHide: boolean;
}

export const NoticeAdd = () => {
  const { push } = useHistory();
  const [context, setContext] = useState('');
  const [hide, setHide] = useState(false);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  return (
    <>
      <AdminH1>Notice</AdminH1>

      <Card>
        <div className="space-y-6 px-4 py-6 sm:px-6 md:px-8">
          <TextField
            label="title"
            helper={errors.title?.message}
            {...register('title', { required: 'This field is required' })}
          />
          <div style={{ width: '745px' }}>
            <Editor onModelChange={(model: any) => setContext(model)} />
          </div>

          <div className="px-4 py-6 sm:px-6 md:px-8">
            <div className="flex space-x-2">
              <span>IsHide</span>
              <Toggle onChange={() => setHide(!hide)} checked={hide} />
              <p className="text-gray-400">숨김 활성화 여부</p>
            </div>
          </div>
        </div>

        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          <Button
            text="Cancel"
            to={`/admin/notices`}
            className="h-10 text-sm outlined-gray-800 hover:bg-gray-50"
          />
          <Button
            text="Add"
            className="h-10 text-sm filled-indigo-600"
            disabled={!watch('title') || !context}
            onClick={handleSubmit(async (data) => {
              data.context = context;
              data.isHide = hide;
              const result = await api.post<Notice>(`/admin/notices`, data);
              push(`/admin/notices/${result.data.id}`);
            })}
          />
        </div>
      </Card>
    </>
  );
};
