import React, { FC, useRef, useState } from 'react';
import { useEffect } from 'react';
import { XIcon } from './icons';
import mammoth from 'mammoth';

import { Overlay } from './Overlay';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import ThirdPartyDataConsentEn0 from '../assets/img/third-party-data-consent-en-0.jpg';
import ThirdPartyDataConsentEn1 from '../assets/img/third-party-data-consent-en-1.jpg';
import ThirdPartyDataConsentEn2 from '../assets/img/third-party-data-consent-en-2.jpg';
import ThirdPartyDataConsentKr0 from '../assets/img/third-party-data-consent-kr-0.jpg';
import ThirdPartyDataConsentKr1 from '../assets/img/third-party-data-consent-kr-1.jpg';
import ThirdPartyDataConsentKr2 from '../assets/img/third-party-data-consent-kr-2.jpg';

interface PracticeAreaPopupProps {
  open: boolean;
  onClose: () => void;
}

export const ThirdPartyDataConsentPopup: FC<PracticeAreaPopupProps> = ({
  open,
  onClose,
}) => {
  const [isKorean, setIsKorean] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const thirdPartyDataConsentKr = [
    {
      id: 1,
      filename: ThirdPartyDataConsentKr0,
    },

    {
      id: 2,
      filename: ThirdPartyDataConsentKr1,
    },

    {
      id: 3,
      filename: ThirdPartyDataConsentKr2,
    },
  ];

  const thirdPartyDataConsentEn = [
    {
      id: 1,
      filename: ThirdPartyDataConsentEn0,
    },

    {
      id: 2,
      filename: ThirdPartyDataConsentEn1,
    },

    {
      id: 3,
      filename: ThirdPartyDataConsentEn2,
    },
  ];

  // 언어에 따라 terms 데이터를 전환
  const thirdPartyDataConsent = isKorean
    ? thirdPartyDataConsentKr
    : thirdPartyDataConsentEn;

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === thirdPartyDataConsent.length - 1 ? 0 : prevIndex + 1,
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? thirdPartyDataConsent.length - 1 : prevIndex - 1,
    );
  };

  // 현재 보여줄 팝업 데이터
  const currentPopup = thirdPartyDataConsent[currentIndex];

  if (!open) return <></>;
  return (
    <div className="fixed z-10 inset-0 grid place-items-center overflow-hidden">
      <Overlay open={open} onClose={onClose} />
      <div
        style={{
          maxHeight: '90vh',
          maxWidth: '40vw',
        }}
        className="relative flex flex-col space-y-2    shadow-lg rounded-lg bg-white overflow-hidden"
      >
        <div className="flex justify-between p-3">
          <h1 className="text-xs sm:text-lg font-bold text-left sm:text-center">
            {isKorean
              ? '개인정보 수집 및 이용 동의'
              : 'Consent to the Collection '}
          </h1>
          <div className="flex-1 flex justify-end space-x-4">
            <button
              type="button"
              className="px-3 text-xs sm:text-sm sm:text-center  py-2 border-2 border-black bg-transparent text-black rounded-md hover:bg-gray-100 focus:outline-none"
              onClick={() => {
                setIsKorean(!isKorean);
                setCurrentIndex(0);
              }}
            >
              {'KR / EN'}
            </button>
            <button onClick={onClose}>
              <XIcon />
            </button>
          </div>
        </div>

        <div className="relative  w-full overflow-y-auto rounded-lg">
          {/* 흰색 배경과 공백을 위한 div */}
          <img
            src={currentPopup.filename}
            className="w-full h-full object-contain cursor-pointer rounded-lg"
          />
        </div>
        <div className="absolute bottom-4 right-4 flex space-x-2">
          {thirdPartyDataConsentKr.length !== 1 && (
            <>
              <button
                className="p-2 w-10 h-10 bg-white bg-opacity-50 hover:bg-opacity-75 rounded-full shadow-lg"
                onClick={(e) => {
                  e.stopPropagation();
                  handlePrev();
                }}
              >
                <span>&#9664;</span>
              </button>
              <button
                className="p-2 w-10 h-10 bg-white bg-opacity-50 hover:bg-opacity-75 rounded-full shadow-lg"
                onClick={(e) => {
                  e.stopPropagation();
                  handleNext();
                }}
              >
                <span>&#9654;</span>
              </button>
            </>
          )}
        </div>

        <div className="flex justify-center mt-2 mb-2 space-x-2">
          {thirdPartyDataConsent.length !== 1 &&
            thirdPartyDataConsent.map((_, index) => (
              <span
                key={index}
                className={`block ${
                  currentIndex === index
                    ? 'w-6 h-2 bg-gray-100'
                    : 'w-2 h-2 bg-gray-400'
                } rounded-full`}
              ></span>
            ))}
        </div>
      </div>
    </div>
  );
};
