import React, { FC, useState } from 'react';

import { XIcon } from './icons';

import { Overlay } from './Overlay';
import PrivacyPolicyKr0 from '../assets/img/privacy-policy-kr-0.jpg';
import PrivacyPolicyKr1 from '../assets/img/privacy-policy-kr-1.jpg';
import PrivacyPolicyKr2 from '../assets/img/privacy-policy-kr-2.jpg';
import PrivacyPolicyKr3 from '../assets/img/privacy-policy-kr-3.jpg';
import PrivacyPolicyKr4 from '../assets/img/privacy-policy-kr-4.jpg';
import PrivacyPolicyKr5 from '../assets/img/privacy-policy-kr-5.jpg';

import PrivacyPolicyEn0 from '../assets/img/privacy-policy-en-0.jpg';
import PrivacyPolicyEn1 from '../assets/img/privacy-policy-en-1.jpg';
import PrivacyPolicyEn2 from '../assets/img/privacy-policy-en-2.jpg';

interface PracticeAreaPopupProps {
  open: boolean;
  onClose: () => void;
}

export const PrivacyPolicyPopup: FC<PracticeAreaPopupProps> = ({
  open,
  onClose,
}) => {
  const [isKorean, setIsKorean] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const privacyPolicyKr = [
    {
      id: 1,
      filename: PrivacyPolicyKr0,
    },

    {
      id: 2,
      filename: PrivacyPolicyKr1,
    },

    {
      id: 3,
      filename: PrivacyPolicyKr2,
    },

    {
      id: 4,
      filename: PrivacyPolicyKr3,
    },

    {
      id: 5,
      filename: PrivacyPolicyKr4,
    },

    {
      id: 6,
      filename: PrivacyPolicyKr5,
    },
  ];

  const privacyPolicyEn = [
    {
      id: 1,
      filename: PrivacyPolicyEn0,
    },

    {
      id: 2,
      filename: PrivacyPolicyEn1,
    },

    {
      id: 3,
      filename: PrivacyPolicyEn2,
    },
  ];

  const privacyPolicy = isKorean ? privacyPolicyKr : privacyPolicyEn;

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === privacyPolicy.length - 1 ? 0 : prevIndex + 1,
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? privacyPolicy.length - 1 : prevIndex - 1,
    );
  };

  const currentPopup = privacyPolicy[currentIndex];

  if (!open) return <></>;
  return (
    <div className="fixed z-10 inset-0 grid place-items-center overflow-hidden">
      <Overlay open={open} onClose={onClose} />

      <div
        style={{
          maxHeight: '90vh',
          maxWidth: '40vw',
        }}
        className="relative flex flex-col space-y-2    shadow-lg rounded-lg bg-white overflow-hidden"
      >
        <div className="flex justify-between p-3">
          <h1 className="text-xs sm:text-lg font-bold text-left sm:text-center">
            {isKorean ? '개인정보 처리방침' : 'Privacy Policy'}
          </h1>
          <div className="flex-1 flex justify-end space-x-4">
            <button
              type="button"
              className="px-3 py-2  text-xs sm:text-sm border-2 border-black bg-transparent text-black rounded-md hover:bg-gray-100 focus:outline-none"
              onClick={() => {
                setIsKorean(!isKorean);
                setCurrentIndex(0);
              }}
            >
              {'KR / EN'}
            </button>
            <button onClick={onClose}>
              <XIcon />
            </button>
          </div>
        </div>

        <div className="relative  w-full overflow-y-auto rounded-lg">
          <img
            src={currentPopup.filename}
            className="w-full h-full object-contain cursor-pointer rounded-lg"
          />
        </div>
        <div className="absolute bottom-4 right-4 flex space-x-2">
          {privacyPolicyKr.length !== 1 && (
            <>
              <button
                className="p-2 w-10 h-10 bg-white bg-opacity-50 hover:bg-opacity-75 rounded-full shadow-lg"
                onClick={(e) => {
                  e.stopPropagation();
                  handlePrev();
                }}
              >
                <span>&#9664;</span>
              </button>
              <button
                className="p-2 w-10 h-10 bg-white bg-opacity-50 hover:bg-opacity-75 rounded-full shadow-lg"
                onClick={(e) => {
                  e.stopPropagation();
                  handleNext();
                }}
              >
                <span>&#9654;</span>
              </button>
            </>
          )}
        </div>

        <div className="flex justify-center mt-2 mb-2 space-x-2">
          {privacyPolicy.length !== 1 &&
            privacyPolicy.map((_, index) => (
              <span
                key={index}
                className={`block ${
                  currentIndex === index
                    ? 'w-6 h-2 bg-gray-100'
                    : 'w-2 h-2 bg-gray-400'
                } rounded-full`}
              ></span>
            ))}
        </div>
      </div>
    </div>
  );
};
