import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { ImageUplaod } from '../../components/ImageUpload';
import { Labeled } from '../../components/Labeled';
import { Select } from '../../components/Select';
import { TextField } from '../../components/TextField';
import { api } from '../../plugins/axios';
import { Editor } from '../../plugins/froala';
import {
  postAdminH1,
  postHasContent,
  postHasDate,
  postHasDescription,
  postHasPriority,
  postHasSubType,
  postHasThumbnail,
  postHasUrl,
  postPath,
  usePostType,
} from '../../post-config';
import { Post, PostSubType } from '../../types';
import { AdminH1 } from '../components/AdminH1';
import { Toggle } from '../../components/Toggle';

export const PostAdd = () => {
  const { push } = useHistory();
  const postType = usePostType(true);
  const [title, setTitle] = useState('');
  const [subType, setSubType] = useState<string>(
    PostSubType.LEGAL_PRACTICE_DIVISION,
  );
  const [description, setDescription] = useState('');
  const [url, setUrl] = useState('');
  const [priority, setPriority] = useState(0);
  const [dateString, setDateString] = useState('');
  const [thumbnail, setThumbnail] = useState<File | null>(null);
  const [content, setContent] = useState('');
  const [isMainPost, setIsMainPost] = useState(false);

  async function add() {
    const postData: any = {
      title,
      subType,
      description,
      url,
      priority,
      dateString,
      content,
      isMainPost,
      type: postType,
    };
    if (thumbnail) {
      const formData = new FormData();
      formData.append('file', thumbnail);
      const { data: url } = await api.post('/s3/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      postData.thumbnail = url;
    }

    await api
      .post<Post>(`/admin/posts/add`, postData)
      .then(({ data: post }) => {
        if (isMainPost) {
          api.patch(`/admin/posts/${post.id}/main`);
        }
        push(`/admin/${postPath(postType)}/${post.id}`);
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  }

  return (
    <>
      <AdminH1>{postAdminH1(postType)}</AdminH1>

      <Card>
        <div className="space-y-6 px-4 py-6 sm:px-6 md:px-8">
          {postHasSubType(postType) && (
            <Select label="Type" onChange={(e) => setSubType(e.target.value)}>
              <option
                label={PostSubType.LEGAL_PRACTICE_DIVISION}
                value={PostSubType.LEGAL_PRACTICE_DIVISION}
              />
              <option
                label={PostSubType.PUBLIC_AND_PROFESSIONAL_INTEREST_DIVISION}
                value={PostSubType.PUBLIC_AND_PROFESSIONAL_INTEREST_DIVISION}
              />
            </Select>
          )}
          <TextField
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          {postHasDescription(postType) && (
            <TextField
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          )}
          {postHasUrl(postType) && (
            <TextField
              label="URL"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          )}
          {postHasDate(postType) && (
            <TextField
              label="Date"
              value={dateString}
              onChange={(e) => setDateString(e.target.value)}
            />
          )}
          {postHasPriority(postType) && (
            <TextField
              label="Priority"
              type="number"
              value={priority}
              onChange={(e) => setPriority(Number(e.target.value))}
            />
          )}
          {postHasThumbnail(postType) && (
            <ImageUplaod
              label="Thumbnail"
              file={thumbnail}
              onChange={(e) =>
                e.target.validity.valid &&
                e.target.files &&
                setThumbnail(e.target.files.item(0))
              }
            />
          )}
          {postHasContent(postType) && (
            <Labeled label="Content">
              <div style={{ width: '745px' }}>
                <Editor onModelChange={(model: any) => setContent(model)} />
              </div>
            </Labeled>
          )}
          <div className="flex space-x-2 ">
            <Toggle
              onChange={() => setIsMainPost(!isMainPost)}
              checked={isMainPost}
            />
            <p className="text-gray-400">대표 게시글 여부</p>
          </div>
        </div>

        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          <Button
            text="Cancel"
            to={`/admin/${postPath(postType)}`}
            className="h-10 text-sm outlined-gray-800 hover:bg-gray-50"
          />
          <Button
            text="Add"
            className="h-10 text-sm filled-indigo-600 hover:bg-indigo-700"
            disabled={!title}
            onClick={add}
          />
        </div>
      </Card>
    </>
  );
};
